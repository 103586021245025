// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-approved-indications-js": () => import("./../../../src/pages/approved-indications.js" /* webpackChunkName: "component---src-pages-approved-indications-js" */),
  "component---src-pages-biomarker-testing-biomarker-overview-js": () => import("./../../../src/pages/biomarker-testing/biomarker-overview.js" /* webpackChunkName: "component---src-pages-biomarker-testing-biomarker-overview-js" */),
  "component---src-pages-biomarker-testing-msi-mmr-js": () => import("./../../../src/pages/biomarker-testing/msi-mmr.js" /* webpackChunkName: "component---src-pages-biomarker-testing-msi-mmr-js" */),
  "component---src-pages-biomarker-testing-pd-l-1-js": () => import("./../../../src/pages/biomarker-testing/pd-l1.js" /* webpackChunkName: "component---src-pages-biomarker-testing-pd-l-1-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-dosing-dose-modifications-js": () => import("./../../../src/pages/dosing/dose-modifications.js" /* webpackChunkName: "component---src-pages-dosing-dose-modifications-js" */),
  "component---src-pages-dosing-options-js": () => import("./../../../src/pages/dosing/options.js" /* webpackChunkName: "component---src-pages-dosing-options-js" */),
  "component---src-pages-dosing-preparation-storage-administration-js": () => import("./../../../src/pages/dosing/preparation-storage-administration.js" /* webpackChunkName: "component---src-pages-dosing-preparation-storage-administration-js" */),
  "component---src-pages-efficacy-adjuvant-renal-cell-carcinoma-js": () => import("./../../../src/pages/efficacy/adjuvant-renal-cell-carcinoma.js" /* webpackChunkName: "component---src-pages-efficacy-adjuvant-renal-cell-carcinoma-js" */),
  "component---src-pages-efficacy-advanced-biliary-tract-cancer-combination-therapy-js": () => import("./../../../src/pages/efficacy/advanced-biliary-tract-cancer-combination-therapy.js" /* webpackChunkName: "component---src-pages-efficacy-advanced-biliary-tract-cancer-combination-therapy-js" */),
  "component---src-pages-efficacy-advanced-endometrial-carcinoma-js": () => import("./../../../src/pages/efficacy/advanced-endometrial-carcinoma.js" /* webpackChunkName: "component---src-pages-efficacy-advanced-endometrial-carcinoma-js" */),
  "component---src-pages-efficacy-advanced-or-recurrent-endometrial-carcinoma-js": () => import("./../../../src/pages/efficacy/advanced-or-recurrent-endometrial-carcinoma.js" /* webpackChunkName: "component---src-pages-efficacy-advanced-or-recurrent-endometrial-carcinoma-js" */),
  "component---src-pages-efficacy-advanced-renal-cell-carcinoma-js": () => import("./../../../src/pages/efficacy/advanced-renal-cell-carcinoma.js" /* webpackChunkName: "component---src-pages-efficacy-advanced-renal-cell-carcinoma-js" */),
  "component---src-pages-efficacy-cervical-cancer-combination-chemoradiotherapy-js": () => import("./../../../src/pages/efficacy/cervical-cancer-combination-chemoradiotherapy.js" /* webpackChunkName: "component---src-pages-efficacy-cervical-cancer-combination-chemoradiotherapy-js" */),
  "component---src-pages-efficacy-cervical-cancer-first-line-combination-therapy-js": () => import("./../../../src/pages/efficacy/cervical-cancer-first-line-combination-therapy.js" /* webpackChunkName: "component---src-pages-efficacy-cervical-cancer-first-line-combination-therapy-js" */),
  "component---src-pages-efficacy-cervical-cancer-second-line-monotherapy-js": () => import("./../../../src/pages/efficacy/cervical-cancer-second-line-monotherapy.js" /* webpackChunkName: "component---src-pages-efficacy-cervical-cancer-second-line-monotherapy-js" */),
  "component---src-pages-efficacy-classical-hodgkin-lymphoma-js": () => import("./../../../src/pages/efficacy/classical-hodgkin-lymphoma.js" /* webpackChunkName: "component---src-pages-efficacy-classical-hodgkin-lymphoma-js" */),
  "component---src-pages-efficacy-cutaneous-squamous-cell-carcinoma-js": () => import("./../../../src/pages/efficacy/cutaneous-squamous-cell-carcinoma.js" /* webpackChunkName: "component---src-pages-efficacy-cutaneous-squamous-cell-carcinoma-js" */),
  "component---src-pages-efficacy-early-stage-triple-negative-breast-cancer-js": () => import("./../../../src/pages/efficacy/early-stage-triple-negative-breast-cancer.js" /* webpackChunkName: "component---src-pages-efficacy-early-stage-triple-negative-breast-cancer-js" */),
  "component---src-pages-efficacy-esophageal-carcinoma-js": () => import("./../../../src/pages/efficacy/esophageal-carcinoma.js" /* webpackChunkName: "component---src-pages-efficacy-esophageal-carcinoma-js" */),
  "component---src-pages-efficacy-gastric-adenocarcinoma-js": () => import("./../../../src/pages/efficacy/gastric-adenocarcinoma.js" /* webpackChunkName: "component---src-pages-efficacy-gastric-adenocarcinoma-js" */),
  "component---src-pages-efficacy-hnscc-first-line-combination-therapy-js": () => import("./../../../src/pages/efficacy/hnscc-first-line-combination-therapy.js" /* webpackChunkName: "component---src-pages-efficacy-hnscc-first-line-combination-therapy-js" */),
  "component---src-pages-efficacy-hnscc-first-line-monotherapy-js": () => import("./../../../src/pages/efficacy/hnscc-first-line-monotherapy.js" /* webpackChunkName: "component---src-pages-efficacy-hnscc-first-line-monotherapy-js" */),
  "component---src-pages-efficacy-index-js": () => import("./../../../src/pages/efficacy/index.js" /* webpackChunkName: "component---src-pages-efficacy-index-js" */),
  "component---src-pages-efficacy-melanoma-adjuvant-therapy-js": () => import("./../../../src/pages/efficacy/melanoma-adjuvant-therapy.js" /* webpackChunkName: "component---src-pages-efficacy-melanoma-adjuvant-therapy-js" */),
  "component---src-pages-efficacy-melanoma-monotherapy-js": () => import("./../../../src/pages/efficacy/melanoma-monotherapy.js" /* webpackChunkName: "component---src-pages-efficacy-melanoma-monotherapy-js" */),
  "component---src-pages-efficacy-merkel-cell-carcinoma-js": () => import("./../../../src/pages/efficacy/merkel-cell-carcinoma.js" /* webpackChunkName: "component---src-pages-efficacy-merkel-cell-carcinoma-js" */),
  "component---src-pages-efficacy-msi-h-dmmr-colorectal-cancer-js": () => import("./../../../src/pages/efficacy/msi-h-dmmr-colorectal-cancer.js" /* webpackChunkName: "component---src-pages-efficacy-msi-h-dmmr-colorectal-cancer-js" */),
  "component---src-pages-efficacy-msi-h-dmmr-js": () => import("./../../../src/pages/efficacy/msi-h-dmmr.js" /* webpackChunkName: "component---src-pages-efficacy-msi-h-dmmr-js" */),
  "component---src-pages-efficacy-nmibc-js": () => import("./../../../src/pages/efficacy/nmibc.js" /* webpackChunkName: "component---src-pages-efficacy-nmibc-js" */),
  "component---src-pages-efficacy-nsclc-adjuvant-therapy-js": () => import("./../../../src/pages/efficacy/nsclc-adjuvant-therapy.js" /* webpackChunkName: "component---src-pages-efficacy-nsclc-adjuvant-therapy-js" */),
  "component---src-pages-efficacy-nsclc-first-line-monotherapy-js": () => import("./../../../src/pages/efficacy/nsclc-first-line-monotherapy.js" /* webpackChunkName: "component---src-pages-efficacy-nsclc-first-line-monotherapy-js" */),
  "component---src-pages-efficacy-nsclc-nonsquamous-first-line-combination-therapy-js": () => import("./../../../src/pages/efficacy/nsclc-nonsquamous-first-line-combination-therapy.js" /* webpackChunkName: "component---src-pages-efficacy-nsclc-nonsquamous-first-line-combination-therapy-js" */),
  "component---src-pages-efficacy-nsclc-perioperative-regimen-js": () => import("./../../../src/pages/efficacy/nsclc-perioperative-regimen.js" /* webpackChunkName: "component---src-pages-efficacy-nsclc-perioperative-regimen-js" */),
  "component---src-pages-efficacy-nsclc-second-line-monotherapy-js": () => import("./../../../src/pages/efficacy/nsclc-second-line-monotherapy.js" /* webpackChunkName: "component---src-pages-efficacy-nsclc-second-line-monotherapy-js" */),
  "component---src-pages-efficacy-nsclc-squamous-first-line-combination-therapy-js": () => import("./../../../src/pages/efficacy/nsclc-squamous-first-line-combination-therapy.js" /* webpackChunkName: "component---src-pages-efficacy-nsclc-squamous-first-line-combination-therapy-js" */),
  "component---src-pages-efficacy-pmbcl-js": () => import("./../../../src/pages/efficacy/pmbcl.js" /* webpackChunkName: "component---src-pages-efficacy-pmbcl-js" */),
  "component---src-pages-efficacy-triple-negative-breast-cancer-js": () => import("./../../../src/pages/efficacy/triple-negative-breast-cancer.js" /* webpackChunkName: "component---src-pages-efficacy-triple-negative-breast-cancer-js" */),
  "component---src-pages-efficacy-urothelial-cancer-first-line-combination-therapy-js": () => import("./../../../src/pages/efficacy/urothelial-cancer-first-line-combination-therapy.js" /* webpackChunkName: "component---src-pages-efficacy-urothelial-cancer-first-line-combination-therapy-js" */),
  "component---src-pages-efficacy-urothelial-carcinoma-first-line-monotherapy-js": () => import("./../../../src/pages/efficacy/urothelial-carcinoma-first-line-monotherapy.js" /* webpackChunkName: "component---src-pages-efficacy-urothelial-carcinoma-first-line-monotherapy-js" */),
  "component---src-pages-efficacy-urothelial-carcinoma-second-line-monotherapy-js": () => import("./../../../src/pages/efficacy/urothelial-carcinoma-second-line-monotherapy.js" /* webpackChunkName: "component---src-pages-efficacy-urothelial-carcinoma-second-line-monotherapy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-indication-earlier-stage-cancers-js": () => import("./../../../src/pages/indication/earlier-stage-cancers.js" /* webpackChunkName: "component---src-pages-indication-earlier-stage-cancers-js" */),
  "component---src-pages-indication-selected-indication-js": () => import("./../../../src/pages/indication/selected-indication.js" /* webpackChunkName: "component---src-pages-indication-selected-indication-js" */),
  "component---src-pages-key-you-video-js": () => import("./../../../src/pages/key-you-video.js" /* webpackChunkName: "component---src-pages-key-you-video-js" */),
  "component---src-pages-prescribing-information-js": () => import("./../../../src/pages/prescribing-information.js" /* webpackChunkName: "component---src-pages-prescribing-information-js" */),
  "component---src-pages-resources-access-reimbursement-support-js": () => import("./../../../src/pages/resources/access-reimbursement-support.js" /* webpackChunkName: "component---src-pages-resources-access-reimbursement-support-js" */),
  "component---src-pages-resources-hcp-and-patient-resources-js": () => import("./../../../src/pages/resources/hcp-and-patient-resources.js" /* webpackChunkName: "component---src-pages-resources-hcp-and-patient-resources-js" */),
  "component---src-pages-resources-key-you-patient-support-program-js": () => import("./../../../src/pages/resources/key-you-patient-support-program.js" /* webpackChunkName: "component---src-pages-resources-key-you-patient-support-program-js" */),
  "component---src-pages-resources-mechanism-of-action-js": () => import("./../../../src/pages/resources/mechanism-of-action.js" /* webpackChunkName: "component---src-pages-resources-mechanism-of-action-js" */),
  "component---src-pages-resources-surgeon-resources-js": () => import("./../../../src/pages/resources/surgeon-resources.js" /* webpackChunkName: "component---src-pages-resources-surgeon-resources-js" */),
  "component---src-pages-safety-adverse-reactions-adjuvant-renal-cell-carcinoma-js": () => import("./../../../src/pages/safety/adverse-reactions/adjuvant-renal-cell-carcinoma.js" /* webpackChunkName: "component---src-pages-safety-adverse-reactions-adjuvant-renal-cell-carcinoma-js" */),
  "component---src-pages-safety-adverse-reactions-advanced-biliary-tract-cancer-combination-therapy-js": () => import("./../../../src/pages/safety/adverse-reactions/advanced-biliary-tract-cancer-combination-therapy.js" /* webpackChunkName: "component---src-pages-safety-adverse-reactions-advanced-biliary-tract-cancer-combination-therapy-js" */),
  "component---src-pages-safety-adverse-reactions-advanced-endometrial-carcinoma-js": () => import("./../../../src/pages/safety/adverse-reactions/advanced-endometrial-carcinoma.js" /* webpackChunkName: "component---src-pages-safety-adverse-reactions-advanced-endometrial-carcinoma-js" */),
  "component---src-pages-safety-adverse-reactions-advanced-or-recurrent-endometrial-carcinoma-js": () => import("./../../../src/pages/safety/adverse-reactions/advanced-or-recurrent-endometrial-carcinoma.js" /* webpackChunkName: "component---src-pages-safety-adverse-reactions-advanced-or-recurrent-endometrial-carcinoma-js" */),
  "component---src-pages-safety-adverse-reactions-advanced-renal-cell-carcinoma-js": () => import("./../../../src/pages/safety/adverse-reactions/advanced-renal-cell-carcinoma.js" /* webpackChunkName: "component---src-pages-safety-adverse-reactions-advanced-renal-cell-carcinoma-js" */),
  "component---src-pages-safety-adverse-reactions-cervical-cancer-combination-chemoradiotherapy-js": () => import("./../../../src/pages/safety/adverse-reactions/cervical-cancer-combination-chemoradiotherapy.js" /* webpackChunkName: "component---src-pages-safety-adverse-reactions-cervical-cancer-combination-chemoradiotherapy-js" */),
  "component---src-pages-safety-adverse-reactions-cervical-cancer-first-line-combination-therapy-js": () => import("./../../../src/pages/safety/adverse-reactions/cervical-cancer-first-line-combination-therapy.js" /* webpackChunkName: "component---src-pages-safety-adverse-reactions-cervical-cancer-first-line-combination-therapy-js" */),
  "component---src-pages-safety-adverse-reactions-cervical-cancer-second-line-monotherapy-js": () => import("./../../../src/pages/safety/adverse-reactions/cervical-cancer-second-line-monotherapy.js" /* webpackChunkName: "component---src-pages-safety-adverse-reactions-cervical-cancer-second-line-monotherapy-js" */),
  "component---src-pages-safety-adverse-reactions-classical-hodgkin-lymphoma-js": () => import("./../../../src/pages/safety/adverse-reactions/classical-hodgkin-lymphoma.js" /* webpackChunkName: "component---src-pages-safety-adverse-reactions-classical-hodgkin-lymphoma-js" */),
  "component---src-pages-safety-adverse-reactions-cutaneous-squamous-cell-carcinoma-js": () => import("./../../../src/pages/safety/adverse-reactions/cutaneous-squamous-cell-carcinoma.js" /* webpackChunkName: "component---src-pages-safety-adverse-reactions-cutaneous-squamous-cell-carcinoma-js" */),
  "component---src-pages-safety-adverse-reactions-early-stage-triple-negative-breast-cancer-js": () => import("./../../../src/pages/safety/adverse-reactions/early-stage-triple-negative-breast-cancer.js" /* webpackChunkName: "component---src-pages-safety-adverse-reactions-early-stage-triple-negative-breast-cancer-js" */),
  "component---src-pages-safety-adverse-reactions-esophageal-carcinoma-js": () => import("./../../../src/pages/safety/adverse-reactions/esophageal-carcinoma.js" /* webpackChunkName: "component---src-pages-safety-adverse-reactions-esophageal-carcinoma-js" */),
  "component---src-pages-safety-adverse-reactions-gastric-adenocarcinoma-js": () => import("./../../../src/pages/safety/adverse-reactions/gastric-adenocarcinoma.js" /* webpackChunkName: "component---src-pages-safety-adverse-reactions-gastric-adenocarcinoma-js" */),
  "component---src-pages-safety-adverse-reactions-hnscc-first-line-combination-therapy-js": () => import("./../../../src/pages/safety/adverse-reactions/hnscc-first-line-combination-therapy.js" /* webpackChunkName: "component---src-pages-safety-adverse-reactions-hnscc-first-line-combination-therapy-js" */),
  "component---src-pages-safety-adverse-reactions-hnscc-first-line-monotherapy-js": () => import("./../../../src/pages/safety/adverse-reactions/hnscc-first-line-monotherapy.js" /* webpackChunkName: "component---src-pages-safety-adverse-reactions-hnscc-first-line-monotherapy-js" */),
  "component---src-pages-safety-adverse-reactions-index-js": () => import("./../../../src/pages/safety/adverse-reactions/index.js" /* webpackChunkName: "component---src-pages-safety-adverse-reactions-index-js" */),
  "component---src-pages-safety-adverse-reactions-melanoma-adjuvant-therapy-js": () => import("./../../../src/pages/safety/adverse-reactions/melanoma-adjuvant-therapy.js" /* webpackChunkName: "component---src-pages-safety-adverse-reactions-melanoma-adjuvant-therapy-js" */),
  "component---src-pages-safety-adverse-reactions-melanoma-monotherapy-js": () => import("./../../../src/pages/safety/adverse-reactions/melanoma-monotherapy.js" /* webpackChunkName: "component---src-pages-safety-adverse-reactions-melanoma-monotherapy-js" */),
  "component---src-pages-safety-adverse-reactions-merkel-cell-carcinoma-js": () => import("./../../../src/pages/safety/adverse-reactions/merkel-cell-carcinoma.js" /* webpackChunkName: "component---src-pages-safety-adverse-reactions-merkel-cell-carcinoma-js" */),
  "component---src-pages-safety-adverse-reactions-msi-h-dmmr-colorectal-cancer-js": () => import("./../../../src/pages/safety/adverse-reactions/msi-h-dmmr-colorectal-cancer.js" /* webpackChunkName: "component---src-pages-safety-adverse-reactions-msi-h-dmmr-colorectal-cancer-js" */),
  "component---src-pages-safety-adverse-reactions-msi-h-dmmr-js": () => import("./../../../src/pages/safety/adverse-reactions/msi-h-dmmr.js" /* webpackChunkName: "component---src-pages-safety-adverse-reactions-msi-h-dmmr-js" */),
  "component---src-pages-safety-adverse-reactions-nmibc-js": () => import("./../../../src/pages/safety/adverse-reactions/nmibc.js" /* webpackChunkName: "component---src-pages-safety-adverse-reactions-nmibc-js" */),
  "component---src-pages-safety-adverse-reactions-nsclc-adjuvant-therapy-js": () => import("./../../../src/pages/safety/adverse-reactions/nsclc-adjuvant-therapy.js" /* webpackChunkName: "component---src-pages-safety-adverse-reactions-nsclc-adjuvant-therapy-js" */),
  "component---src-pages-safety-adverse-reactions-nsclc-first-line-monotherapy-js": () => import("./../../../src/pages/safety/adverse-reactions/nsclc-first-line-monotherapy.js" /* webpackChunkName: "component---src-pages-safety-adverse-reactions-nsclc-first-line-monotherapy-js" */),
  "component---src-pages-safety-adverse-reactions-nsclc-nonsquamous-first-line-combination-therapy-js": () => import("./../../../src/pages/safety/adverse-reactions/nsclc-nonsquamous-first-line-combination-therapy.js" /* webpackChunkName: "component---src-pages-safety-adverse-reactions-nsclc-nonsquamous-first-line-combination-therapy-js" */),
  "component---src-pages-safety-adverse-reactions-nsclc-perioperative-regimen-js": () => import("./../../../src/pages/safety/adverse-reactions/nsclc-perioperative-regimen.js" /* webpackChunkName: "component---src-pages-safety-adverse-reactions-nsclc-perioperative-regimen-js" */),
  "component---src-pages-safety-adverse-reactions-nsclc-second-line-monotherapy-js": () => import("./../../../src/pages/safety/adverse-reactions/nsclc-second-line-monotherapy.js" /* webpackChunkName: "component---src-pages-safety-adverse-reactions-nsclc-second-line-monotherapy-js" */),
  "component---src-pages-safety-adverse-reactions-nsclc-squamous-first-line-combination-therapy-js": () => import("./../../../src/pages/safety/adverse-reactions/nsclc-squamous-first-line-combination-therapy.js" /* webpackChunkName: "component---src-pages-safety-adverse-reactions-nsclc-squamous-first-line-combination-therapy-js" */),
  "component---src-pages-safety-adverse-reactions-pmbcl-js": () => import("./../../../src/pages/safety/adverse-reactions/pmbcl.js" /* webpackChunkName: "component---src-pages-safety-adverse-reactions-pmbcl-js" */),
  "component---src-pages-safety-adverse-reactions-triple-negative-breast-cancer-js": () => import("./../../../src/pages/safety/adverse-reactions/triple-negative-breast-cancer.js" /* webpackChunkName: "component---src-pages-safety-adverse-reactions-triple-negative-breast-cancer-js" */),
  "component---src-pages-safety-adverse-reactions-urothelial-cancer-first-line-combination-therapy-js": () => import("./../../../src/pages/safety/adverse-reactions/urothelial-cancer-first-line-combination-therapy.js" /* webpackChunkName: "component---src-pages-safety-adverse-reactions-urothelial-cancer-first-line-combination-therapy-js" */),
  "component---src-pages-safety-adverse-reactions-urothelial-carcinoma-first-line-monotherapy-js": () => import("./../../../src/pages/safety/adverse-reactions/urothelial-carcinoma-first-line-monotherapy.js" /* webpackChunkName: "component---src-pages-safety-adverse-reactions-urothelial-carcinoma-first-line-monotherapy-js" */),
  "component---src-pages-safety-adverse-reactions-urothelial-carcinoma-second-line-monotherapy-js": () => import("./../../../src/pages/safety/adverse-reactions/urothelial-carcinoma-second-line-monotherapy.js" /* webpackChunkName: "component---src-pages-safety-adverse-reactions-urothelial-carcinoma-second-line-monotherapy-js" */),
  "component---src-pages-safety-monitoring-managing-adverse-reactions-js": () => import("./../../../src/pages/safety/monitoring-managing-adverse-reactions.js" /* webpackChunkName: "component---src-pages-safety-monitoring-managing-adverse-reactions-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */)
}

