export const cancerTypes = {
    // 1: { // may be permanently removed
    //     id: 1,
    //     name: "Hepatocellular Carcinoma (HCC)",
    //     modifier: "Advanced",
    //     key: "hep",
    //     indications: [
    //         1
    //     ]
    // },
    2: {
        id: 2,
        name: "Melanoma",
        modifier: "Advanced Melanoma or Adjuvant Therapy for",
        key: "mel",
        indications: [
            2,
            3,
        ]
    },
    3: {
        id: 3,
        name: "NSCLC",
        modifiedTitle: "Non&#8288;–&#8288;Small Cell Lung Cancer (NSCLC)",
        modifier: "Advanced Non&#8288;–&#8288;Small Cell Lung Cancer (NSCLC), Neoadjuvant Followed by Adjuvant Therapy for NSCLC, or Adjuvant Therapy for",
        key: "nsc",
        indications: [
            4,
            5,
            6,
            7,
            //35,
            34,
            33,
        ]
    },
    4: {
        id: 4,
        name: "Renal Cell Carcinoma (RCC)",
        modifier: "Adjuvant Treatment for RCC or Advanced",
        key: "ren",
        indications: [
            29,
            8,
            31
        ]
    },
    5: {
        id: 5,
        name: "Head and Neck Squamous Cell Carcinoma (HNSCC)",
        modifier: "Advanced",
        key: "hns",
        indications: [
            9,
            10,
            11
        ]
    },
    // 6: { //may be permanently removed
    //     id: 6,
    //     name: "Small Cell Lung Cancer (SCLC)",
    //     modifier: "Metastatic",
    //     key: "scl",
    //     indications: [
    //         12
    //     ]
    // },
    7: {
        id: 7,
        name: "Cutaneous Squamous Cell Carcinoma (cSCC)",
        modifier: "Advanced",
        key: "csc",
        indications: [
            13
        ]
    },
    8: {
        id: 8,
        name: "Merkel Cell Carcinoma (MCC)",
        modifier: "Advanced",
        key: "mer",
        indications: [
            14
        ]
    },
    9: {
        id: 9,
        name: "Urothelial Cancer",
        modifier: "Locally Advanced or Metastatic",
        key: "uro",
        indications: [
            36,
            15,
            16
        ]
    },
    10: {
        id: 10,
        name: "Non-muscle Invasive Bladder Cancer (NMIBC)",
        modifier: "High-Risk",
        key: "bla",
        indications: [
            17
        ]
    },
    11: {
        id: 11,
        name: "HER2-Negative Gastric or GEJ Adenocarcinoma",
        key: "gas",
        modifier: "Advanced",
        indications: [
            18
        ]
    },
    12: {
        id: 12,
        name: "Esophageal or GEJ Carcinoma",
        key: "eso",
        modifier: "Advanced",
        indications: [
            19
        ]
    },
    13: {
        id: 13,
        name: "Classical Hodgkin Lymphoma (cHL)",
        modifier: "Relapsed or Refractory",
        key: "lym",
        indications: [
            20
        ]
    },
    14: {
        id: 14,
        name: "Primary Mediastinal Large B-cell Lymphoma (PMBCL)",
        modifier: "Refractory or Relapsed",
        key: "pmb",
        indications: [
            21
        ]
    },
    15: {
        id: 15,
        name: "MSI&#8288;-&#8288;H/dMMR Cancers",
        modifier: "Advanced",
        key: "msi",
        indications: [
            22
        ]
    },
    16: {
        id: 16,
        name: "MSI&#8288;-&#8288;H/dMMR Colorectal Cancer (CRC)",
        modifier: "Advanced",
        key: "crc",
        indications: [
            23
        ]
    },
    17: {
        id: 17,
        name: "Cervical Cancer",
        modifier: "Advanced",
        key: "cer",
        indications: [
            37,
            28,
            24,
        ]
    },
    // 18: { // Removing as a part of Accelerated Approval Removal
    //     id: 18,
    //     name: "TMB-H Cancers",
    //     modifier: "Advanced",
    //     key: "tmb",
    //     indications: [
    //         25
    //     ]
    // },
    19: {
        id: 19,
        name: "Triple-Negative Breast Cancer (TNBC)",
        modifier: "High-Risk Early-Stage or Advanced ",
        key: "bre",
        indications: [
            27,
            26
        ]
    },
    21: {
        id: 21,
        name: "Endometrial Carcinoma",
        modifier: "Advanced",
        key: "endo",
        indications: [
            1,
            30,
            32,
        ]
    },
    22: {
        id: 22,
        name: "Biliary Tract Cancer (BTC)",
        modifier: "Advanced",
        key: "biliary",
        indications: [
           35
        ]
    },
};

/**
 * This is a list of group strings that map to cancerTypes or groups of cancerTypes. useGroup will validate a received
 * group string against these keys, and pass that string to FilterProvider, which will initialize filteredCancerTypes for
 * a page to the array that corresponds to the string.
 */
export const cancerTypeKeyIdMap = {
    'cer' : [17],
    'eso' : [12],
    'gas' : [11],
    // 'hep' : [1],
    'mer' : [8],
    'msi' : [15],
    'crc' : [16],
    'nsc' : [3],
    'ren' : [4],
    // 'tmb' : [18],
    'bre' : [19],
    'uro' : [9],
    'can' : [3,10,11,15,16,17,18,19],
    'car' : [1,4,5,7,8,9,12],
    'lym' : [13],
    'bla' : [10],
    'mel' : [2],
    'hns' : [5],
    'scl' : [],
    'org' : [11,12],
    'csc' : [7],
    'ref' : [13,14],
    'ror' : [13,14],
    'pmb' : [14],
    'end' : [21],
    'all' : [],
    'bil' : [22]
};
